<template>
    <div class="statistics-table">
        <div class="main-content">
            <ul class="header">
                <li>技师类型</li>
                <li>空闲</li>
                <li>上钟</li>
                <li>圈牌</li>
                <li>休假</li>
                <li>未上班</li>
                <li>下班</li>
                <li>人数</li>
            </ul>

        <ul class="total" v-if="tableTotal">
                <li>各项总计</li>
                <li>{{tableTotal["空闲"]}}</li>
                <li>{{tableTotal["上钟"]}}</li>
                <li>{{tableTotal["圈牌"]}}</li>
                <li>{{tableTotal["休假"]}}</li>
                <li>{{tableTotal["未上班数"]}}</li>
                <li>{{tableTotal["已下班数"]}}</li>
                <li>{{tableTotal["技师总数"]}}</li>
            </ul>
           <ul class="data-list"
                v-for="(item,index) in tableData"
                :key="index">
                <li>{{index}}</li>
                <li>{{item["空闲"]}}</li>
                <li>{{item["上钟"]}}</li>
                <li>{{item["圈牌"]}}</li>
                <li>{{item["休假"]}}</li>
                <li>{{item["未上班数"]}}</li>
                <li>{{item["下班"]}}</li>
                <li>{{item["技师总数"]}}</li>
            </ul>

        </div>
       <daqi-copyright></daqi-copyright>
    </div>
</template>
<script>
	import table from '@/api/table'
  import { mapState } from 'vuex';
	import DaqiCopyright from "../../../components/daqi-copyright";

	export default {
		name:"technician-state",
		components:{DaqiCopyright},
		data(){
			return {
				tableData:null,
				tableHead:null,
				tableTotal:null,
			}
		},
    computed: {
    ...mapState(['userStatus']),
    },
		watch:{
			'userStatus.curs_node_id_2':{
				deep:true,
				handler:'getTechnicianStatistics'
			},
		},
		methods:{
			/**
			 * 获取技师分类上班状态统计人数
			 */
			getTechnicianStatistics(){
				return new Promise((resolve,reject) =>{
					table.getTechnicianStatistics().then(res =>{
                        this.tableData = res.data.tableData
						this.tableHead = res.data.tableHead
                        this.tableTotal = res.data.tableTotal
						resolve()
					}).catch(err =>{
						console.log("err",err.msg)
						reject()
					})
				})
			},
		},
		created(){
			this.getTechnicianStatistics()
		}
	}
</script>
<style lang="scss" type="text/scss" scoped>
    @import "~@/assets/style/public.scss";

    .statistics-table{
        overflow-x:hidden;
        overflow:auto;
        width:100vw;
        height:calc(100vh - 470px);
    }
    .main-content{
        min-height:calc(100% - 101px);
    }
    ul{
        display:flex;
        padding:0 15px 0 0;

        li{
            flex:1;
            text-align:center;
        }

        li:first-child{
            min-width:140px;
            @extend %ellipsis;
        }
    }

    .header{
        padding:0 30px;
        color:#999999;
        font-size:24px;
        line-height:80px;
        height:80px;
    }

    .total{
        margin:0 30px;
        background:#FFFFFF;
        border-radius:6px;
        color:$high-light-color;
        line-height:100px;
        height:100px;
    }

    .data-list{
        margin:0 30px;
        background:#FFFFFF;
        border-radius:6px;
        color:rgb(51,51,51);
        line-height:100px;
        height:100px;
    }
    .total,.data-list{
        li:not(:first-child){
            font-size:28px;
        }
        li:not(:first-child):after{
            content:"人";
            display:inline-block;
            font-size:22px;
        }
        li:first-child{
            font-size:24px;
        }
    }
</style>
